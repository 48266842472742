.scroll {
    -webkit-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    height: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 4px;
    padding-top: 8px;
}
.scroll-hidden > div {
    overflow-y: hidden;
}

.scroll::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
}

.scroll::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background-color: rgba(32, 33, 36, 0.541);
}

.scroll:hover::-webkit-scrollbar-thumb {
    background-color: rgba(32, 33, 36, 0.38);
}

.page-break {
    page-break-after: always;
}

#menu- > .MuiPaper-elevation8 {
    width: auto !important;
}

@layer base {
    img {
        display: initial;
    }
}
